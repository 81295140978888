import { SpanRole } from "@/components/footer/team/components";
import { HoverTwoSpan } from "@/shared/UI/HoverTwoSpan";
import SvgSelector from "@/shared/UI/SvgSelector";
import { isHandbookSlug, isLegalSlug } from "@/shared/constants/routes";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import { debounce, throttle } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { paddings } from "../page/text.components";

const initialExpanded = "share";

const generatePreviewText = (title, description, url, pathname) => `${title} 
%0A%0A
${description}
%0A%0A
${
  isLegalSlug(pathname)
    ? "Подробнее в Документах"
    : isHandbookSlug(pathname)
      ? "Подробнее в Справочнике"
      : "Читайте в Артподготовке"
}:
%0A
${url}
%0A%0A
`;

export const ShareButtons = ({
  copy_link,
  title,
  description,
  isSlugPage = false,
}) => {
  const [expanded, setExpanded] = useState(initialExpanded);

  const { pathname } = useRouter();
  const setExpandedDelayed = useCallback(
    debounce(throttle(setExpanded, 200), 80),
    []
  );

  const links = useMemo(
    () => [
      {
        id: 1,
        name: "fb",
        text: "Шернуть",
        url: `https://www.facebook.com/sharer.php?u=${copy_link}`,
      },
      {
        id: 2,
        name: "iks",
        text: "Иксануть",
        url: `https://twitter.com/intent/tweet?url=${copy_link}&text=${generatePreviewText(
          title,
          description,
          copy_link,
          pathname
        )}`,
      },
      {
        id: 3,
        name: "vk",
        text: "Вкнуть",
        url: `
      https://vk.com/share.php?url=${copy_link}&title=${generatePreviewText(
        title,
        description,
        copy_link,
        pathname
      )}`,
      },
      {
        id: 4,
        name: "tg",
        text: "Отправить",
        url: `https://telegram.me/share/url?url=%00&text=${generatePreviewText(
          title,
          description,
          copy_link,
          pathname
        )}`,
      },
    ],
    []
  );

  const handleMouseLeave = () => setExpandedDelayed(initialExpanded);
  return (
    <ShareButtonsBody isSlugPage={isSlugPage} onMouseLeave={handleMouseLeave}>
      <ShareCopy
        isSlugPage={isSlugPage}
        url={copy_link}
        setExpandedDelayed={setExpandedDelayed}
        expanded={expanded}
      />
      {links.map((link) => (
        <ShareButton
          isSlugPage={isSlugPage}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          key={link.id}
          onMouseEnter={() => setExpandedDelayed(link.name)}
          isExpanded={link.name === expanded}
        >
          <SvgSelector
            svg={link.name === expanded ? "share-bg-fill" : "share-bg"}
          />
          <ShareButtonBody isSlugPage={isSlugPage}>
            <div>
              <SvgSelector svg={`share-${link.name}`} />
            </div>
            <ShareButtonLabel
              isSlugPage={isSlugPage}
              isExpanded={link.name === expanded}
            >
              {link.text}
            </ShareButtonLabel>
          </ShareButtonBody>
        </ShareButton>
      ))}
    </ShareButtonsBody>
  );
};

const ShareCopy = ({ setExpandedDelayed, expanded, url, isSlugPage }) => {
  const [state, setstate] = useState(0);

  const copy = () => {
    if (state != 1) {
      navigator.clipboard.writeText(url);
      setstate(1);
      setTimeout(() => {
        setstate(0);
      }, 2000);
    }
  };

  return (
    <ShareButton
      isSlugPage={isSlugPage}
      onMouseEnter={() => setExpandedDelayed("share")}
      isExpanded={"share" === expanded}
      onMouseUp={copy}
    >
      <SvgSelector svg={"share" === expanded ? "share-bg-fill" : "share-bg"} />
      <ShareButtonBody isSlugPage={isSlugPage}>
        <div>
          <SvgSelector svg={`share-share`} />
        </div>
        <ShareButtonLabel
          isSlugPage={isSlugPage}
          isExpanded={"share" === expanded}
        >
          <HoverTwoSpan active={state}>
            <SpanRole>Скопировано</SpanRole>
            <SpanRole>Поделиться</SpanRole>
          </HoverTwoSpan>
        </ShareButtonLabel>
      </ShareButtonBody>
    </ShareButton>
  );
};

const ShareButtonsBody = styled.div`
  display: flex;
  padding: 0 0.25em;

  ${({ isSlugPage }) =>
    isSlugPage
      ? `
      ${mixins.gap4}
  ${paddings.side46}
  justify-content: flex-start;
  margin-bottom: 3.056vw;
    @media ${breakpoints.desktopXl} {
      margin-bottom: 3.44vw;
    }
    @media ${breakpoints.tabletL} {
      margin-bottom: 4.301vw;
    }
    @media ${breakpoints.tabletM} {
      margin-bottom: 5.922vw;
    }
    @media ${breakpoints.mobile} {
      margin-bottom: 8.777vw;
    }
    `
      : `
      gap: 0.25em;
      justify-content: center;
      `}
`;
const ShareButtonBody = styled.div`
  display: flex;
  align-items: center;
  gap: 0.15em;
  margin: 0 auto;
  & > div {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    @media ${breakpoints.mobile} {
      width: 1.1em;
      height: 1.1em;
    }
    & > svg {
      width: 90%;
      height: 90%;
    }
    ${({ isSlugPage }) =>
      isSlugPage &&
      `
    width: 17px;
    height: 17px;
    @media ${breakpoints.desktopXl} {
      width: 1.329vw;
      height: 1.329vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.662vw;
      height: 1.662vw;
    }
    @media ${breakpoints.tabletM} {
      width: 2.288vw;
      height: 2.288vw;
    }
    @media ${breakpoints.mobile} {
      width: 5.329vw;
      height: 5.329vw;
    }
    & > svg {
      width: 100%;
      height: 100%;
    }
    `}
  }
`;

const ShareButton = styled.a`
  color: var(--wh6);
  overflow: hidden;
  display: flex;
  gap: 0.15em;
  align-items: center;
  transition: width 0.3s;

  ${({ isSlugPage }) =>
    isSlugPage
      ? `
  width: 28px;
  height: 28px;
  padding: 0 0.34em;
  @media ${breakpoints.desktopXl} {
    padding: 0 0.4em;
      width: 2.189vw;
      height: 2.189vw;
  }
  @media ${breakpoints.tabletL} {
      width: 2.737vw;
      height: 2.737vw;
  }
  @media ${breakpoints.tabletM} {
      width: 3.769vw;
      height: 3.769vw;
  }
  @media ${breakpoints.mobile} {
    padding: 0 1.567vw;
      width: 8.777vw;
      height: 8.777vw;
  }
  `
      : `
  width: 24px;
  height: 24px;
  padding: 0 4px;
  @media ${breakpoints.desktopXl} {
    width: 1.876vw;
    height: 1.876vw;
    padding: 0 0.45vw;
  }
  @media ${breakpoints.tabletL} {
    width: 2.346vw;
    padding: 0 0.56vw;
    height: 2.346vw;
  }
  @media ${breakpoints.tabletM} {
    width: 3.23vw;
    padding: 0 0.65vw;
    height: 3.23vw;
  }
  @media ${breakpoints.mobile} {
    width: 7.524vw;
    padding: 0 1.3vw;
    height: 7.524vw;
  }
  `}

  flex-shrink: 0;

  ${({ isExpanded, isSlugPage }) =>
    isExpanded &&
    (isSlugPage
      ? `
      width: 114px;
      @media ${breakpoints.desktopXl} {
    width: 8.913vw;
  }
  @media ${breakpoints.tabletL} {
    width: 11.144vw;
  }
  @media ${breakpoints.tabletM} {
    width: 15.343vw;
  }
  @media ${breakpoints.mobile} {
    width: 35.737vw;
  }`
      : `width: 100px;
  @media ${breakpoints.desktopXl} {
    width: 7.819vw;
  }
  @media ${breakpoints.tabletL} {
    width: 9.775vw;
  }
  @media ${breakpoints.tabletM} {
    width: 13.459vw;
  }
  @media ${breakpoints.mobile} {
    width: 31.348vw;
  }`)}

  position: relative;

  & > svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    & path {
      transition: fill-opacity 0.3s;
    }
  }

  &:hover {
    & > svg path {
      fill-opacity: 0.08;
    }
  }
  &:active {
    & > svg path {
      fill-opacity: 0.04;
    }
  }
`;

const ShareButtonLabel = styled.p`
  ${fonts.manrope4}
  ${mixins.fs10}
  line-height: 133%;
  letter-spacing: 0.03em;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  & ${SpanRole} {
    color: var(--wh6);
    text-align: left;
  }

  ${({ isExpanded }) =>
    isExpanded
      ? `opacity: 1;
    visibility: visible;`
      : `
    opacity: 0;
  visibility: hidden;
    `};
  ${({ isSlugPage }) => (isSlugPage ? `${mixins.fs11}` : `${mixins.fs10}`)};
  & span {
    font-size: inherit;
  }
`;
