import { Tag_HbCard, Tags_HbCard } from "../components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css";
import { FreeMode, Mousewheel, Navigation } from "swiper/modules";
import styled from "styled-components";
import { TagSlug } from "../page/components";

export const TagsHbCard = ({ data, isCard = true }) => {
  return (
    <Tags_HbCard>
      <SwiperSt
        isCard={isCard}
        modules={[FreeMode, Navigation, Mousewheel]}
        freeMode={true}
        allowTouchMove={true}
        mousewheel={{
          forceToAxis: true,
        }}
        navigation={true}
        spaceBetween={4}
        slidesPerView={"auto"}
        // centeredSlides
      >
        {data?.map((tag) => (
          <SwiperSlide key={tag.id}>
            {isCard ? (
              <Tag_HbCard>{tag.name}</Tag_HbCard>
            ) : (
              <TagSlug>{tag.name}</TagSlug>
            )}
          </SwiperSlide>
        ))}
      </SwiperSt>
    </Tags_HbCard>
  );
};

const SwiperSt = styled(Swiper)`
  & .swiper-slide {
    width: fit-content;
  }
  & .swiper-button-prev,
  .swiper-button-next {
    transition: opacity 0.2s;
    top: initial;
    bottom: 0;
    max-height: 100%;
    width: 13%;
    ${({ isCard }) => `background-image: linear-gradient(
      270deg,
      ${isCard ? "#121212" : "#0d0d0d"} 30%,
      rgba(18, 18, 18, 0) 100%
    );`}

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    &::before {
      transition: opacity 0.3s;
      content: "";
      opacity: 0.8;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("/assets/arrow-next.svg");
      background-repeat: no-repeat;
      background-size: auto 60%;
      background-position: 80% center;
    }

    &::after {
      display: none;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
    }
    &:active {
      &::before {
        opacity: 0.8;
      }
    }
  }
  & .swiper-button-prev {
    left: -2px;
    transform: scale(-1, 1);
  }
  & .swiper-button-next {
    right: -2px;
  }
  & .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
`;
