import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css";
import { FreeMode, Mousewheel, Navigation } from "swiper/modules";
import { Avatar } from "../header/userContent/settings/components";
import { HoverTwoSpan, Spans } from "@/shared/UI/HoverTwoSpan";
import { useState } from "react";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";

export const Authors = ({ isSlug = false, data }) => {
  return (
    <Wr isSlug={isSlug}>
      <Slider
        modules={[FreeMode, Navigation, Mousewheel]}
        freeMode={true}
        navigation={true}
        allowTouchMove={true}
        mousewheel={{
          forceToAxis: true,
        }}
        spaceBetween={isSlug ? 16 : 10}
        slidesPerView={"auto"}
      >
        {data?.map((author) => (
          <SwiperSlide key={author.id}>
            <Author isSlug={isSlug} data={author} />
          </SwiperSlide>
        ))}
      </Slider>
    </Wr>
  );
};

const Author = ({ data, isSlug }) => {
  const { avatar, display_name, telegram_link, role } = data;
  const [hover, setHover] = useState(true);
  return (
    <AuthorWr
      isSlug={isSlug}
      href={telegram_link}
      target="_blank"
      rel="noopener noreferrer"
      onMouseOver={() => setHover(false)}
      onMouseOut={() => setHover(true)}
    >
      <Avatar avatar={avatar ?? "/assets/avatar.jpg"} />
      <AuthorName isSlug={isSlug}>{display_name}</AuthorName>
      <HoverTwoSpan active={hover}>
        <AuthorLabel isSlug={isSlug}>{role}</AuthorLabel>
        <AuthorLabel isSlug={isSlug}>Написать</AuthorLabel>
      </HoverTwoSpan>
    </AuthorWr>
  );
};
const AuthorLabel = styled.span`
  ${fonts.artel4}

  color: var(--wh4);
  ${({ isSlug }) =>
    isSlug
      ? `
    ${mixins.fs12}
  line-height: 117%;
  letter-spacing: 0.02em;`
      : `
  ${mixins.fs8}
  line-height: 125%;
  letter-spacing: 0.04em;
  `}
`;

const AuthorName = styled(AuthorLabel)`
  color: var(--wh9);
  grid-column: 2;
  grid-row: 1;
  transition: color 0.3s;
  ${({ isSlug }) =>
    isSlug
      ? `
    ${mixins.fs12}
  line-height: 117%;
  letter-spacing: 0.02em;`
      : `
  ${mixins.fs8}
  line-height: 125%;
  letter-spacing: 0.04em;
  `}
`;
const AuthorWr = styled.a`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  column-gap: 8px;
  @media ${breakpoints.desktopXl} {
    column-gap: 0.625vw;
  }
  @media ${breakpoints.tabletL} {
    column-gap: 0.782vw;
  }
  @media ${breakpoints.tabletM} {
    column-gap: 1.077vw;
  }
  @media ${breakpoints.mobile} {
    column-gap: 2.508vw;
  }
  & ${Spans} {
    grid-column: 2;
    grid-row: 2;
  }
  & ${Avatar} {
    grid-column: 1;
    grid-row: span 2;
    box-shadow: 0 0 4px 0 #fff, 1px 1px 3px 0 rgba(95, 255, 140, 0.4),
      -1px -1px 3px 0 rgba(255, 98, 56, 0.44), 0 0 1px 0 #fff;

    ${({ isSlug }) =>
      isSlug
        ? `
        width: 24px;
        height: 24px;
    @media ${breakpoints.desktopXl} {
      width: 1.876vw;
      height: 1.876vw;
    }
    @media ${breakpoints.tabletL} {
      width: 2.346vw;
      height: 2.346vw;
    }
    @media ${breakpoints.tabletM} {
      width: 3.23vw;
      height: 3.23vw;
    }
    @media ${breakpoints.mobile} {
      width: 7.524vw;
      height: 7.524vw;
    }    
    `
        : `
    width: 15px;
    height: 15px;
    @media ${breakpoints.desktopXl} {
      width: 1.173vw;
      height: 1.173vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.466vw;
      height: 1.466vw;
    }
    @media ${breakpoints.tabletM} {
      width: 2.019vw;
      height: 2.019vw;
    }
    @media ${breakpoints.mobile} {
      width: 4.702vw;
      height: 4.702vw;
    }
    `}
  }
  &:active {
    & ${AuthorName} {
      color: var(--wh4);
    }
  }
`;
const Wr = styled.div`
  ${({ isSlug }) =>
    isSlug
      ? `
      margin-bottom: 20px;
      height: 28px;
      @media ${breakpoints.desktopXl} {
        height: 2.189vw;
        margin-bottom: 1.564vw;
      }
      @media ${breakpoints.tabletL} {
        height: 2.737vw;
        margin-bottom: 1.955vw;
      }
      @media ${breakpoints.tabletM} {
        height: 3.769vw;
        margin-bottom: 2.692vw;
      }
      @media ${breakpoints.mobile} {
        margin-bottom: 6.27vw;
        height: 8.777vw;
      }
  `
      : `
        height: 20px;
        margin-bottom: 16px;
        @media ${breakpoints.desktopXl} {
          margin-bottom: 1.251vw;
          height: 1.564vw;
        }
        @media ${breakpoints.tabletL} {
          margin-bottom: 1.564vw;
          height: 1.955vw;
        }
        @media ${breakpoints.tabletM} {
          margin-bottom: 2.153vw;
          height: 2.692vw;
        }
        @media ${breakpoints.mobile} {
          margin-bottom: 5.016vw;
          height: 6.27vw;
        }
      `}
`;

const Slider = styled(Swiper)`
  padding-left: 3px;
  @media ${breakpoints.desktopXl} {
    padding-left: 0.235vw;
  }
  @media ${breakpoints.tabletL} {
    padding-left: 0.293vw;
  }
  @media ${breakpoints.tabletM} {
    padding-left: 0.404vw;
  }
  @media ${breakpoints.mobile} {
    padding-left: 0.94vw;
  }

  & .swiper-slide {
    width: fit-content;
  }
  & .swiper-button-prev,
  .swiper-button-next {
    transition: opacity 0.2s;
    top: initial;
    bottom: 0;
    max-height: 100%;
    width: 13%;
    background-image: linear-gradient(
      270deg,
      #0d0d0d 30%,
      rgba(18, 18, 18, 0) 100%
    );
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    &::before {
      transition: opacity 0.3s;
      content: "";
      opacity: 0.8;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("/assets/arrow-next.svg");
      background-repeat: no-repeat;
      background-size: auto 60%;
      background-position: 80% center;
    }

    &::after {
      display: none;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
    }
    &:active {
      &::before {
        opacity: 0.8;
      }
    }
  }
  & .swiper-button-prev {
    left: -2px;
    transform: scale(-1, 1);
  }
  & .swiper-button-next {
    right: -2px;
  }
  & .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
`;
